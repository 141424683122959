<template>
  <div class="list-c" v-loading="loading">
    <div v-if="noData" class="no-data">
      <img :src="img" alt="">
    </div>
    <table v-if="list.length > 0">
      <tr class="th-back">
        <th class="bot-tb" style="width: 50%">名称</th>
        <th style="width: 40%">操作</th>
      </tr>
      <tr v-for="(item, index) of list" :key="index">
        <td class="bot-tb top-bor t-l">
          <img v-if="item.type == 'pdf'" :src="pdf" alt="" class="tb-icon">
          <img v-else-if="item.type == 'ocx' || item.type == 'doc'" :src="ocx" alt="" class="tb-icon">
          <img v-else-if="item.type == 'lsx'" :src="lsx" alt="" class="tb-icon">
          <img v-else :src="image" alt="" class="tb-icon">
          <span style="display: inline;">{{item.title}}</span>
        </td>
        <td class="top-bor">
          <div class="unload-btn" @click="fileDetail(item.attFiles)" style="margin-right: 10px;">
            <img :src="previewIcon" alt="" class="un-icon">
            <span>预览</span>
          </div>
          <div class="unload-btn" @click="unlaod(item.attFiles, item.title)">
            <!-- <i class="el-icon-s-flag"></i> -->
            <img :src="unlaodIcon" alt="" class="un-icon">
            <span>下载</span>
          </div>
        </td>
      </tr>
    </table>
    <Pagination :total="total" :size="size" :page="current" @PaginationFun="PaginationFun"></Pagination>
  </div>
</template>

<script>
import { getStandardList } from '@/api/web'
import { fileDownload } from '@/api/login.js'
import Pagination from '@/components/pagination/Pagination.vue'
export default {
  components: {
    Pagination
  },
  name: 'ListC',
  data () {
    return {
      img: require('../../../assets/images/nodata.png'),
      image: require('../../../assets/images/icon_image.png'),
      pdf: require('../../../assets/images/icon_pdf.png'),
      ocx: require('../../../assets/images/icon_doc.png'),
      lsx: require('../../../assets/images/icon_xls.png'),
      unlaodIcon: require('../../../assets/images/unload_b.png'),
      previewIcon: require('../../../assets/images/preview.png'),
      noData: false,
      loading: false,
      list: [],
      total: 0,
      current: 1,
      size: 10
    }
  },
  watch: {
    '$route.fullPath': function (n, o) {
      this.current = 1
      this.initialData()
    }
  },
  created () {
    if (this.$route.query.cid) {
      this.initialData()
    }
  },
  mounted () {},
  methods: {
    fileDetail (path) {
      window.open(process.env.VUE_APP_IMG_URL + path, '_blank')
    },
    unlaod (path, name) {
      fileDownload(path, name)
    },
    initialData () {
      this.loading = true
      const data = {
        cmsType: this.$route.query.cid,
        pageSize: this.size,
        pageNo: this.current
      }
      getStandardList(data).then(res => {
        this.loading = false
        if (res.code === '000') {
          this.total = res.result.total
          this.current = res.result.current
          if (res.result.total === 0) {
            this.noData = true
          } else {
            this.noData = false
          }
          const list = res.result.records
          list.forEach(element => {
            const str = element.attFiles.substr(element.attFiles.length - 3, element.attFiles.length - 1)
            element.type = str
          })
          this.list = list
        }
      })
    },
    PaginationFun (e) {
      this.current = e
      this.initialData()
    }
  }
}
</script>

<style lang="less" scoped>
.list-c{
  padding: 20px 25px 80px 25px;
  min-height: 520px;
  position: relative;
}
table{
  width: 100%;
  overflow: hidden;
  border: 1px solid #E9E9E9;
  background-color: #ffffff;
}
th{
  height: 34px;
  text-align: center;
}
td{
  height: 36px;
  border-top: 1px solid #E9E9E9;
  text-align: center;
}
.bot-tb{
  border-right: 1px solid #E9E9E9;
}
.t-l{
  padding-left: 20px;
  text-align: left;
}
.unload-btn{
  display: inline-block;
  font-size: 13px;
  padding: 0 15px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #4E84FF;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
}
.unload-btn:hover{
  opacity: 0.7;
}
.from{
  padding: 25px 50px;
  font-size: 16px;
}
.xian{
  margin: 15px 0;
  height: 1px;
  background-color: #cdcdcd;
}
.from-box{
  width: 550px;
  margin: auto;
  padding: 30px 25px 0 0;
}
.no-data{
  height: 400px;
  width: 400px;
  margin: auto;
}
.no-data img{
  display: block;
  height: 100%;
  width: 100%;
}
.tb-icon{
  height: 18px;
  width: 18px;
  vertical-align: middle;
  margin-right: 10px;
}
.un-icon{
  height: 12px;
  width: 14px;
  vertical-align: middle;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
</style>
